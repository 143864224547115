import React from "react";
import { Helmet } from "react-helmet"
import StandardLayout from "../layout/StandardLayout";

const Services = () => {
    return <StandardLayout>

        <Helmet>
            <title>Our Services - Burke Shipping Services</title>
        </Helmet>

        <div className="c-hero" style={{backgroundImage: "url(/images/services-hero.jpg)"}} ></div>

        <div className="u-fill-1 u-pad-large u-pad-xlarge@m">
            <div className="l-wrap u-width-5 u-align-center">
                <h1 className="c-heading +h1 u-marg-bottom">Services</h1>
                <p className="u-font-large@m u-marg-bottom u-marg-bottom-large@m">
                    Burke Shipping Services Ltd. can provide your company with a range of services all certified
                    to ISO certification quality standard, these include ship agency, stevedoring and
                    warehousing.
                </p>
                <p className="u-font-large@m">
                    Burke Shipping Services Ltd. have a proven track record in offering high quality ships agency services to many shipping companies across the world.
                </p>
            </div>
        </div>


        <div className="l-wrap">
            <div className="c-media-text +background-image-fill">
                <div className="c-media-text__contents +contents-flipped">
                    <div className="c-media-text__a">
                        <div className="u-pad-top-bottom-xlarge@m">
                            <p className="u-font-large@m u-marg-bottom u-marg-bottom-large@m">Our Stevedores are experienced in handling a wide range of cargo types, operating a safe, efficient and excellent stevedoring service</p>

                            <p className="u-font-bold u-marg-bottom">Find out more about:</p>
                            <a href="/ships-agency" className="c-button +1 u-marg-right u-marg-bottom">Ships Agency</a>
                            <a href="/stevedoring" className="c-button +1 u-marg-right u-marg-bottom">Stevedoring</a>
                            <a href="/warehousing" className="c-button +1 u-marg-right u-marg-bottom">Warehousing</a>
                        </div>
                    </div>

                    <div className="c-media-text__b">
                        <div className="c-media-text__media">
                            <img src="/images/services-2.jpg" alt="Stevedore loading lorry"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="u-fill-2 u-color-1 u-pad-large u-pad-xlarge@m">
            <div className="l-wrap u-width-5 u-align-center">
                <p className="u-font-large@m u-marg-bottom u-marg-bottom-large@m">Burke Shipping Services Ltd. currently operate over 200,000 sq ft of flat storage dedicated to agri-products and can provide storage within the port for your goods.</p>
                <p className="u-font-large">We have a wealth of experience in dealing with all types of vessels and the ability to handle a variety of cargoes.</p>
            </div>
        </div>




        <div className="c-splitter"></div>
    </StandardLayout>
};

export default Services;